import React, { useState } from "react";
import validator from "email-validator";
import { toast } from "react-toastify";
import emailjs from "emailjs-com";

const Contact = (props) => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  if (props.data) {
    var name = props.data.name;
    var phone = props.data.phone;
    var email = props.data.email;
    var message = props.data.contactmessage;
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const clearForm = () => {
    setValues({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  const sendEmail = () => {
    setLoading(true);

    let validEmail = validator.validate(values.email);
    if (
      values.name.length > 0 &&
      values.message.length > 0 &&
      values.email.length > 0
    ) {
      if (!validEmail) {
        setLoading(false);
        toast.error("Please enter a valid email!");
      } else {
        let template_params = {
          reply_to: values.email,
          subject: values.subject,
          from_name: values.name,
          message_html: values.message,
        };

        let service_id = "gmail";
        let template_id = "kevin_portfolio_contact_form";
        let user_id = "user_ltmRlSHLhRky0okGYwmce";
        emailjs
          .send(service_id, template_id, template_params, user_id)
          .then(() => {
            toast.success("Your message was sent, thank you!");
            clearForm();
            setLoading(false);
          })
          .catch(() => {
            toast.error("Something went wrong. Please try again later");
            setLoading(false);
          });
      }
    } else {
      toast.error("Please complete all fields.");
      setLoading(false);
    }
  };

  return (
    <section id="contact">
      <div className="row section-head">
        <div className="two columns header-col">
          <h1>
            <span>Get In Touch.</span>
          </h1>
        </div>

        <div className="ten columns">
          <p className="lead">{message}</p>
        </div>
      </div>

      <div className="row">
        <div className="eight columns">
          <fieldset>
            <div>
              <label htmlFor="contactName">
                Name <span className="required">*</span>
              </label>
              <input
                type="text"
                value={values.name}
                size="35"
                id="contactName"
                name="contactName"
                onChange={handleChange("name")}
              />
            </div>

            <div>
              <label htmlFor="contactEmail">
                Email <span className="required">*</span>
              </label>
              <input
                type="text"
                value={values.email}
                size="35"
                id="contactEmail"
                name="contactEmail"
                onChange={handleChange("email")}
              />
            </div>

            <div>
              <label htmlFor="contactSubject">
                Subject <span className="required">*</span>
              </label>
              <input
                type="text"
                value={values.subject}
                size="35"
                id="contactSubject"
                name="contactSubject"
                onChange={handleChange("subject")}
              />
            </div>

            <div>
              <label htmlFor="contactMessage">
                Message <span className="required">*</span>
              </label>
              <textarea
                value={values.message}
                cols="50"
                rows="15"
                id="contactMessage"
                name="contactMessage"
                onChange={handleChange("message")}
              ></textarea>
            </div>

            <div>
              <button className="submit" onClick={sendEmail}>
                Submit
              </button>

              <span id="image-loader">
                {loading ? (
                  <img alt="" src="images/loader.gif" />
                ) : (
                  <span></span>
                )}
              </span>
            </div>
          </fieldset>
        </div>

        <aside className="four columns footer-widgets">
          <div className="widget widget_contact">
            <h4>Email and Phone</h4>
            <p>
              <span>{email}</span>
              <br />
              <span>{phone}</span>
            </p>
          </div>
        </aside>
      </div>
    </section>
  );
};

export default Contact;
