import React, { Component, Fragment } from "react";

export class Skills extends Component {
  render() {
    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      var frontEndSkills = this.props.data.frontEndSkills.map(function (
        skills
      ) {
        var className = "bar-expand " + skills.name.toLowerCase();
        return (
          <li key={skills.name}>
            <span style={{ width: skills.level }} className={className}></span>
            <em>{skills.name}</em>
          </li>
        );
      });

      var backEndSkills = this.props.data.backEndSkills.map(function (skills) {
        var className = "bar-expand " + skills.name.toLowerCase();
        return (
          <li key={skills.name}>
            <span style={{ width: skills.level }} className={className}></span>
            <em>{skills.name}</em>
          </li>
        );
      });

      var deploymentSkills = this.props.data.deploymentSkills.map(function (skills) {
        var className = "bar-expand " + skills.name.toLowerCase();
        return (
          <li key={skills.name}>
            <span style={{ width: skills.level }} className={className}></span>
            <em>{skills.name}</em>
          </li>
        );
      });

      var designSkills = this.props.data.designSkills.map(function (skills) {
        var className = "bar-expand " + skills.name.toLowerCase();
        return (
          <li key={skills.name}>
            <span style={{ width: skills.level }} className={className}></span>
            <em>{skills.name}</em>
          </li>
        );
      });
    }

    return (
      <Fragment>
        <div className="row skill">
          <div className="three columns header-col">
            <h1>
              <span>Front-End</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="bars">
              <ul className="skills">{frontEndSkills}</ul>
            </div>
          </div>
        </div>
        <hr style={{width: "70%", marginLeft:"auto", marginRight:"auto", marginBottom: "5%"}}></hr>
        <div className="row skill">
          <div className="three columns header-col">
            <h1>
              <span>Back-End</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="bars">
              <ul className="skills">{backEndSkills}</ul>
            </div>
          </div>
        </div>

        <hr style={{width: "70%", marginLeft:"auto", marginRight:"auto", marginBottom: "5%"}}></hr>
        <div className="row skill">
          <div className="three columns header-col">
            <h1>
              <span>Deployment</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="bars">
              <ul className="skills">{deploymentSkills}</ul>
            </div>
          </div>
        </div>

        <hr style={{width: "70%", marginLeft:"auto", marginRight:"auto", marginBottom: "5%"}}></hr>
        <div className="row skill">
          <div className="three columns header-col">
            <h1>
              <span>Design</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="bars">
              <ul className="skills">{designSkills}</ul>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Skills;
