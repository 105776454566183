import React, { Component, Fragment } from "react";
import Skills from "./Skills";

class Experiences extends Component {
  render() {
    if (this.props.data) {
      var work = this.props.data.work.map(function (work) {
        return (
          <div key={work.company}>
            <h3>
              {work.title}
              <a href={work.companyWebsite}>
                <img
                  className="company-logo"
                  src={"images/" + work.logo}
                  alt="logo"
                ></img>
              </a>
            </h3>
            <p className="info">
              <a href={work.companyWebsite}>{work.company}</a>
              <span>&bull;</span> <em className="date">{work.years}</em>
            </p>
            <ul style={{listStyle: "circle"}}>
              <li>{work.bullet1}</li>
              <li>{work.bullet2}</li>
              <li>{work.bullet3}</li>
              <li>{work.bullet4}</li>
              {work.bullet5?<li>{work.bullet5}</li> : null}
            </ul>
            {work.demo? <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTZEeW0bfkZbAEHiETERaBhAMFV8xtyx8nTvHJE_VCdT8bd5BA8JITfFVUV6LrU3ODgPBrdhFpMY7l9/embed?start=false&loop=false&delayms=3000" title="HEB Demo" frameborder="0" width="74%" height="299" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{marginBottom: "8%"}}></iframe> : null}
            <br />
          </div>
        );
      });
    }

    return (
      <Fragment>
        <section id="experience">
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Experience</span>
              </h1>
            </div>

            <div className="nine columns main-col">{work}</div>
          </div>
        </section>
        <section id="skills">
          <Skills data={this.props.data} />
        </section>
      </Fragment>
    );
  }
}

export default Experiences;
